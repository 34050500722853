import "./App.css";
import Header from "./components/Header";
import Package from "./components/Package";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./components/CheckoutFormOld";
import { useEffect, useState } from "react";
import axiosServices from "./utils/axios";
import { useLocation, useSearchParams } from "react-router-dom";
import PaymentForm from "./components/PaymentForm";
import PaymentPage from "./components/PaymentPage";
import GeneratePassword from "./components/GeneratePassword";
import Logo from "./images/img.png";
import Navbar from "./components/Navbar";
import { useTranslation } from "react-i18next";
import Loading from "./components/Loading";
import { STRIPE_PUBLISHKEY } from "./config";
import SuccessImage from "./images/successful.png";
import ErrorImage from "./images/error.png";

function App() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  // const slugEncodeValue = location.search;
  const query = new URLSearchParams(location.search);
  const queryString = query.get("coba");
  const sessionId = query.get("session_id");
  const paymentStatus = query.get("payment_status");
  const [accessToken, setAccessToken] = useState();
  const [decodeString, setDecodeString] = useState("");
  // const priceId = query.get("priceId");
  const [user, setUser] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const stripePromise = loadStripe(STRIPE_PUBLISHKEY);
  const [basicDetails, setBasicDetails] = useState(undefined);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [states, setStates] = useState({
    step: 1,
    proccedToPay: false,
  });

  // DECODE THE ACCESS TOKEN

  function doubleBase64Decode(encodedString) {
    let firstDecoded = atob(encodedString);
    let secondDecoded = atob(firstDecoded);

    return setDecodeString(secondDecoded);
  }

  // CONVERT THE DECODE STRING INTO JSON
  var getURLParameters = function (url) {
    // var urlParams = new URLSearchParams(url);
    var result = {};
    var searchIndex = url.indexOf("?");
    if (searchIndex == -1) return result;
    var sPageURL = url.substring(searchIndex + 1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      result[sParameterName[0]] = sParameterName[1];
    }
    localStorage.setItem("accessToken", result?.token);
    return setAccessToken(result?.token);
  };
  useEffect(() => {
    if (queryString) {
      doubleBase64Decode(queryString);
    }
  }, [queryString]);

  useEffect(() => {
    if (decodeString) {
      getURLParameters(decodeString);
    }
  }, [decodeString]);

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user?.language);
    } else {
      i18n.changeLanguage("sw");
    }
  }, [user]);

  // GET USER DETAILS
  useEffect(() => {
    if (accessToken) {
      axiosServices
        .get(`/api/customers/me`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          if (res?.data?.status === "success") {
            axiosServices.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            setUser(res?.data?.user);
            setError(undefined);
          }
        })
        .catch((error) => {
          setError(error);
          setUser(undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [accessToken]);

  useEffect(() => {
    if (sessionId && paymentStatus && user) {
      setIsLoading(false);
      setStates({
        step: 2,
        proccedToPay: true,
      });
      if (paymentStatus === "success") {
        setPaymentSuccess(true);
      }
    }
  }, [sessionId, paymentStatus, user]);

  return (
    <div className="wrapper">
      {/* <Navbar /> */}
      {isLoading && (
        <div className="loading p-3 py-5 ">
          <Loading />
        </div>
      )}
      {paymentSuccess ? (
        <div className="text-center m-auto py-3">
          <img src={SuccessImage} alt="Success Icon" className="m-auto" />
          <p className="my-2 text-green-600 font-bold w-10/12 m-auto">
            {t(
              "Thank You! Your payment has been success, now you can log in to the App"
            )}
          </p>
        </div>
      ) : (
        user?.alreadySubcribe === 1 && (
          <div className="error text-red-800 p-3">
            {t("Session has been expired, please retry!")}{" "}
            {t("or Your payment has been successfull!")}
          </div>
        )
      )}
      {error && (
        <div className="error text-red-800 p-3">
          {t("Session has been expired, please retry!")}
        </div>
      )}
      {user?.alreadySubcribe === 0 && user && (
        <>
          <div className="main-div">
            <div className="leftside-img">
              <img src={Logo} alt={Logo} width="80%" />
            </div>
            <div className="main-container">
              <Header user={user} />
              <div className="timeline-container">
                <div className="password-timeline">
                  <div
                    className={`circle ${
                      states?.step === 1 ? "bg-[#000000] text-white" : ""
                    }`}
                  >
                    1
                  </div>
                </div>
                <div className="line"></div>
                <div className="password-timeline">
                  <div
                    className={`circle ${
                      states?.step === 2 ? "bg-[#000000] text-white" : ""
                    }`}
                  >
                    2
                  </div>
                </div>
              </div>
              <div className="timeline-text">
                <div className="password-text">{t("Basic Details")}</div>
                <div className="password-text">{t("Payment")}</div>
              </div>
              <GeneratePassword
                user={user}
                states={states}
                setStates={setStates}
                varToken={accessToken}
                setBasicDetails={setBasicDetails}
              />
              {states?.proccedToPay && states?.step === 2 && (
                <PaymentPage
                  basicDetails={basicDetails}
                  user={user}
                  stripePromise={stripePromise}
                  varToken={accessToken}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
